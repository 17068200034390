import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import PAYMENT_METHODS from '../../../constants/payment/methods';
import PAYMENT_STATUSES from '../../../constants/payment/statuses';
import messagesMethod from '../../../constants/payment/intlMethods';
import messagesStatuses from '../../../constants/payment/intlStatuses';
import DEFAULT_FILTERS from '../../../constants/table/filters';
import messageById from '../../../functions/intl/messageById';
import localeNumber from '../../../functions/types/localeNumber';
import tableCell from '../../../functions/table/tableCell';
import formatTableHeader from '../../../functions/table/formatTableHeader';
import rowsFromHeader from '../../../functions/table/rowFromHeader';
import downloadTablePreExport from '../../../functions/table/downloadTablePreExport';
import compose from '../../../functions/react/compose';
// eslint-disable-next-line import/no-cycle
import withStickyHeader from '../../Table/withStickyHeader';
import withOptions from '../../Table/withOptions';
import withExport from '../../Table/withExport';
import withColumnsToggle from '../../Table/withColumnsToggle';
import withToggledColumnsSaving from '../../Table/withToggledColumnsSaving';
import withFilters from '../../Table/withFilters';
import PureTable from '../../Table';

/**
 * `Table` component with controls
 * @type {React:::Component}
 */
const Table = compose(
  PureTable,
  withFilters,
  withToggledColumnsSaving,
  withColumnsToggle,
  withExport,
  withOptions,
  withStickyHeader
);

/**
 * Table header messages
 * @type {Object}
 */
const messages = defineMessages({
  productName: {
    id: 'ProfilePartner.productName',
    defaultMessage: 'Tour',
  },
  source: {
    id: 'ProfilePartner.purchasesSource',
    defaultMessage: 'Source',
  },
  creationTs: {
    id: 'ProfilePartner.purchasesOrderFrom',
    defaultMessage: 'Order date',
  },
  partnerProfitForAuthor: {
    id: 'ProfilePartner.purchasesAuthorProfit',
    defaultMessage: 'My affiliate income',
  },
  status: {
    id: 'ProfilePartner.purchasesStatus',
    defaultMessage: 'Status',
  },
  orderNumber: {
    id: 'ProfilePartner.orderNumber',
    defaultMessage: 'Order number',
  },
  travelDate: {
    id: 'ProfilePartner.travelDate',
    defaultMessage: 'Tour date',
  },
  capacity: {
    id: 'ProfilePartner.capacity',
    defaultMessage: 'Number of tourists',
  },
  orderTotalPrice: {
    id: 'ProfilePartner.orderTotalPrice',
    defaultMessage: 'Order cost',
  },
  ticketPrice: {
    id: 'ProfilePartner.ticketPrice',
    defaultMessage: 'Ticket price',
  },
  paymSystemComission: {
    id: 'ProfilePartner.paymSystemComission',
    defaultMessage: 'Payment system commission',
  },
  partnerProfitForAffiliate: {
    id: 'ProfilePartner.partnerProfitForAffiliate',
    defaultMessage: "Partner's commission",
  },
  income: {
    id: 'ProfilePartner.income',
    defaultMessage: "Author's income",
  },
  discount: {
    id: 'ProfilePartner.discount',
    defaultMessage: 'Discount',
  },
  totalAuthorIncome: {
    id: 'ProfilePartner.totalAuthorIncome',
    defaultMessage: 'Total income',
  },
  payoutTs: {
    id: 'ProfilePartner.payoutTs',
    defaultMessage: 'Payment date',
  },
});

/**
 * Messages for exporting from table mechanics
 * @type {Object}
 */
const exportMessages = defineMessages({
  fileName: {
    id: 'ProfilePartner.tableFileName',
    defaultMessage: 'WeGoTrip sales {additional}',
  },
});

/**
 * Payment methods to be displayed in table filters
 * @type {Array[Object]}
 */
const PAYMENT_METHODS_DISPLAY = PAYMENT_METHODS.filter(
  ({ value }) => value !== 'UN'
);

/**
 * Gets all message translations from `ids` where possible (e.g.`PAYMENT_METHODS_DISPLAY` or `PAYMENT_STATUSES`)
 * @param {Function} formatMessage - `formatMessage` from `react-intl`
 * @param {Object} paymentMessages - translations of messages to search by id in `messageById`
 * @param {Array[Object]} ids - values must contain `value` and `title` keys, considered as message id and default message text
 * @returns {String}
 */
function paymentMessagesById(formatMessage, paymentMessages, ids) {
  return ids.map(({ value, title }) =>
    messageById(formatMessage, paymentMessages, value, title)
  );
}

/**
 * Gets table header without titles
 * @param {Boolean} isPartner - if partner data also need to be shown in columns
 * @param {Function} formatMessage - `formatMessage` from `react-intl`
 * @returns - table header without titles
 */
const getHeader = (isPartner, formatMessage) => {
  const paymentMessages = paymentMessagesById.bind(null, formatMessage);
  return [
    { id: 'productName' },
    { id: 'orderNumber', type: 'number', defaultVisible: false },
    {
      id: 'source',
      defaultVisible: false,
      filter: {
        id: 'options',
        props: {
          options: paymentMessages({}, PAYMENT_METHODS_DISPLAY),
        },
      },
    },
    { id: 'creationTs', type: 'date' },
    { id: 'travelDate', type: 'date' },
    { id: 'capacity', type: 'number', defaultVisible: false },
    { id: 'orderTotalPrice', type: 'number' },
    { id: 'discount', type: 'number' },
    isPartner ? { id: 'ticketPrice', type: 'number' } : null,
    { id: 'paymSystemComission', type: 'number' },
    { id: 'partnerProfitForAffiliate', type: 'number' },
    isPartner ? { id: 'partnerProfitForAuthor', type: 'number' } : null,
    { id: 'income', type: 'number' },
    {
      id: 'status',
      filter: {
        id: 'options',
        props: { options: paymentMessages(messagesStatuses, PAYMENT_STATUSES) },
      },
    },
    { id: 'totalAuthorIncome', type: 'number' },
    { id: 'payoutTs', type: 'date' },
  ]
    .filter((elem) => elem !== null)
    .map(({ id, ...h }) => ({ id, title: messages[id], ...h }));
};

/**
 * Gets table cell data from `cellData` object taken from input
 * @param {String} locale - locale code
 * @param {String} currency - currency symbol
 * @param {Function} getMethodMessage - gets text to display in the `"source"` cell from method id in `cellData`, second parameter considered to be default function output
 * @param {Function} getStatusMessage - gets text to display in the `"status"` cell from method id in `cellData`, second parameter considered to be default function output
 * @param {TableHeaderData::id} id - id of the table column current processing cellData located in
 * @param {*} cellData - data to be converted to `TableCellData`
 * @returns {TableCellData}
 */
function getData(
  locale,
  currency,
  getMethodMessage,
  getStatusMessage,
  id,
  cellData
) {
  switch (id) {
    case 'travelDate':
    case 'creationTs':
    case 'payoutTs': {
      try {
        const dateString = cellData.slice(0, 10);
        const date = parse(dateString, 'yyyy-MM-dd', new Date());
        return tableCell(format(date, 'dd.MM.yyyy'), { raw: date });
      } catch (error) {
        return tableCell('', { raw: '' });
      }
    }
    case 'source': {
      const method = PAYMENT_METHODS.find((m) => m.value === cellData);
      return tableCell(method ? getMethodMessage(cellData, method.title) : '-');
    }
    case 'status': {
      const status = PAYMENT_STATUSES.find((s) => s.value === cellData);
      return tableCell(status ? getStatusMessage(cellData, status.title) : '-');
    }
    case 'ticketPrice': {
      const ticketPrice =
        cellData === 0
          ? '-'
          : `${localeNumber(locale, cellData)} ${currency || ''}`;
      return tableCell(ticketPrice);
    }
    case 'orderTotalPrice':
    case 'paymSystemComission':
    case 'partnerProfitForAffiliate':
    case 'partnerProfitForAuthor':
    case 'income':
    case 'potentialIncome':
    case 'totalAuthorIncome':
      return tableCell(`${localeNumber(locale, cellData)} ${currency || ''}`);
    case 'orderNumber':
      return tableCell(cellData, { raw: cellData });
    case 'discount': {
      return tableCell(`${cellData} %`, { raw: cellData });
    }
    default:
      return tableCell(cellData);
  }
}

/**
 * Table with payments
 * @param {Object} $
 * @param {String} $.locale - locale code
 * @param {String} $.currency - currency symbol
 * @param {String} $.currencyCode - currency code (e.g. - `"USD"`)
 * @param {Number} $.fullPaymentsAmount - amount of rows to be downloaded when table is triggered for exporting
 * @param {Array[Object]} $.data - data to be displayed in the table (from `payments` API)
 * @param {Boolean} $.isPartner - show data for partners in columns
 * @param {Function} $.onExportError -  will be called with `Error` object if error occurred during downloading full table while exporting
 */
function ProfilePartnerPurchases({
  locale,
  currency,
  currencyCode,
  isPartner,
  fullPaymentsAmount,
  data = [],
  onExportError = () => {},
  intl: { formatMessage } = {},
}) {
  const getMessage = messageById.bind(null, formatMessage);
  const getMethodMessage = getMessage.bind(null, messagesMethod);
  const getStatusMessage = getMessage.bind(null, messagesStatuses);
  const getDataTemplate = getData.bind(
    this,
    locale,
    currency,
    getMethodMessage,
    getStatusMessage
  );
  const header = formatTableHeader(
    getHeader(isPartner, formatMessage),
    formatMessage
  );

  const preExport = () =>
    downloadTablePreExport(
      'v2/payments/stats/',
      { currency: currencyCode },
      fullPaymentsAmount,
      header,
      getDataTemplate,
      onExportError
    );

  const currentDate = new Date();
  const time = format(currentDate, 'HH-mm-ss');
  const date = format(currentDate, 'yyyy-MM-dd');
  const exportedTableFileName = formatMessage(exportMessages.fileName, {
    additional: `${date} ${time}`,
  });

  return (
    <Table
      fullWidth
      id="purchases"
      keys="orderNumber"
      exportName={exportedTableFileName}
      preExport={preExport}
      filters={DEFAULT_FILTERS}
      header={header}
      rows={rowsFromHeader(header, data, getDataTemplate, true)}
    />
  );
}

export default injectIntl(ProfilePartnerPurchases);
