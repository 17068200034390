import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Button, Typography, useMediaQuery } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import intlStatuses from '../../../constants/payout/intlStatuses';
import { financeEmail } from '../../../constants/contacts/finance';
import tableCell from '../../../functions/table/tableCell';
import formatTableHeader from '../../../functions/table/formatTableHeader';
import rowsFromHeader from '../../../functions/table/rowFromHeader';
import downloadTablePreExport from '../../../functions/table/downloadTablePreExport';
import messageById from '../../../functions/intl/messageById';
import PureTable, { leftRightCellTextAlign } from '../../Table';
import compose from '../../../functions/react/compose';
// eslint-disable-next-line import/no-cycle
import withStickyHeader from '../../Table/withStickyHeader';
import withOptions from '../../Table/withOptions';
import withExport from '../../Table/withExport';
import withColumnsToggle from '../../Table/withColumnsToggle';
import withToggledColumnsSaving from '../../Table/withToggledColumnsSaving';
import withFilters from '../../Table/withFilters';
import DEFAULT_FILTERS from '../../../constants/table/filters';
import { Loader } from '../../Loader';
import Pagination from '../../Pagination';

const useStyles = makeStyles((theme) => ({
  paymentsContainer: {
    minHeight: 'calc(100% + 145px)',
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100% + 165px)',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100% + 220px)',
    },
    ...leftRightCellTextAlign,
  },
  total: {
    marginRight: 90,
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
    },
  },
  sum: {
    marginLeft: 'auto',
  },
  availableToPayout: {
    whiteSpace: 'nowrap',
    width: 120,
    marginLeft: '5%',
    textAlign: 'right',
  },
  payoutsPlate: {
    display: 'flex',
    width: 'calc(100% - 90px)',
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexShrink: 0,
    flexWrap: 'wrap',
    border: '1px solid #F5F5F5',
    boxSizing: 'border-box',
    borderRadius: 8,
    gap: 15,
  },
  payoutsPlateText: {
    width: 540,
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    paddingTop: 10,
    paddingBottom: 32,
  },
  summaryRow: {
    color: grey[500],
    display: 'flex',
    justifyContent: 'space-between',
  },
  payoutsBottomBlock: {
    position: 'fixed',
    paddingBottom: 16,
    bottom: 0,
    width: 'calc(100% - 380px)',
    backgroundColor: 'white',
  },
  payoutsBottomBlockMobile: {
    position: 'fixed',
    paddingBottom: 16,
    bottom: 0,
    width: 'calc(100% - 55px)',
    backgroundColor: 'white',
  },
  noPayoutsText: {
    textAlign: 'center',
    marginTop: 16,
  },
  pagination: {
    marginTop: 6,
  },
}));

/**
 * `Table` component with controls
 * @type {React:::Component}
 */
const Table = compose(
  PureTable,
  withFilters,
  withToggledColumnsSaving,
  withColumnsToggle,
  withExport,
  withOptions,
  withStickyHeader
);

export const messages = defineMessages({
  payoutTs: {
    id: 'ProfileReports.payoutDate',
    defaultMessage: 'Payment date',
  },
  payoutAmount: {
    id: 'ProfileReports.amountOfPayment',
    defaultMessage: 'Amount of payment',
  },
  requisites: {
    id: 'ProfileReports.requisites',
    defaultMessage: 'Requisites',
  },
  noteToPayout: {
    id: 'ProfileReports.note',
    defaultMessage: 'Note',
  },
  status: {
    id: 'ProfileReports.status',
    defaultMessage: 'Status',
  },
});

const headers = [
  { id: 'payoutTs', type: 'date' },
  { id: 'payoutAmount', type: 'number' },
  { id: 'requisites', type: 'string' },
  { id: 'noteToPayout', type: 'string' },
  { id: 'status', type: 'string' },
].map(({ id, ...h }) => ({ id, title: messages[id], ...h }));

// Store the sum outside of the component to prevent value changing to "Calculating..." while paginate
let savedToPayout = null;

const ReportPayouts = ({
  stats: {
    payouts: {
      current = 1,
      pages = 10,
      results,
      payedSum = 0,
      count,
      unpayedSum,
    },
    isFetching,
  },
  formatMessage,
  currencyCode,
  currencySymbol,
  fetchPayouts,
  userActions: { requestPayout },
  showNetworkError = () => {},
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:620px)');
  const [totalToPayout, setTotalToPayout] = useState('');
  const [isSummaryAvailable, setIsSummaryAvailable] = useState(false);

  useEffect(() => {
    if (
      !isFetching &&
      unpayedSum !== null &&
      unpayedSum !== undefined &&
      savedToPayout !== unpayedSum
    ) {
      setTotalToPayout(unpayedSum);
      savedToPayout = unpayedSum;
      setIsSummaryAvailable(true);
    } else if (!isFetching && unpayedSum === undefined) {
      setTotalToPayout(0);
      savedToPayout = 0;
    }
  }, [unpayedSum, isFetching]);

  const getStatusMessage = messageById.bind(null, formatMessage, intlStatuses);
  const renderPayoutsTable = () => {
    if (results === null || !currencyCode)
      return [
        <Loader key="loader" active className="Loader__with-container-top">
          <FormattedMessage
            id="ProfileReports.loadingPayouts"
            defaultMessage="Loading payouts"
          />
        </Loader>,
      ];
    else if (results && results.length) {
      const header = formatTableHeader(headers, formatMessage);

      const getTableData = (id, cellData) => {
        switch (id) {
          case 'payoutTs': {
            if (!cellData) return tableCell('');
            const dateString = cellData.slice(0, 10);
            const date = parse(dateString, 'yyyy-MM-dd', new Date());
            return tableCell(format(date, 'dd.MM.yyyy'), { raw: date });
          }
          case 'payoutAmount':
            return tableCell(`${cellData} ${currencySymbol}`);
          case 'requisites':
            return tableCell(cellData.accountNumber);
          case 'status':
            return tableCell(getStatusMessage(cellData, cellData));
          default:
            return tableCell(cellData);
        }
      };

      const downloadFullTable = () =>
        downloadTablePreExport(
          'v2/payouts/',
          {},
          count,
          header,
          getTableData,
          showNetworkError
        );

      return [
        <div key="table" className={classes.paymentsContainer}>
          <Table
            fullWidth
            id="payouts"
            keys="orderNumber"
            preExport={downloadFullTable}
            filters={DEFAULT_FILTERS}
            header={header}
            rows={rowsFromHeader(header, results, getTableData, true)}
          />
        </div>,
        <Pagination
          key="paginator"
          className={classes.pagination}
          currentPage={current}
          isLoading={isFetching}
          pages={pages}
          onChange={fetchPayouts}
        />,
      ];
    }
    return [
      <Typography
        key="placeholder"
        variant="body1"
        className={classes.noPayoutsText}
      >
        <FormattedMessage
          id="ProfileReports.noPayouts"
          defaultMessage="There were no payouts"
        />
      </Typography>,
    ];
  };

  const payoutsTable = renderPayoutsTable();

  return (
    <React.Fragment>
      {payoutsTable[0]}
      <div
        className={
          isMobile
            ? classes.payoutsBottomBlockMobile
            : classes.payoutsBottomBlock
        }
      >
        <div className={classes.summaryContainer}>
          {payoutsTable[1] ? payoutsTable[1] : null}
          <div className={classes.summaryRow}>
            <Typography variant="body2" className={classes.total}>
              <FormattedMessage
                id="ProfileReports.total"
                defaultMessage="Total"
              />
            </Typography>
            <Typography variant="body2" className={classes.sum}>
              {totalToPayout !== '' || savedToPayout !== null ? (
                `${savedToPayout || totalToPayout} ${currencySymbol}`
              ) : (
                <FormattedMessage
                  id="ProfileReports.calculatingTotal"
                  defaultMessage="Calculating..."
                />
              )}
            </Typography>
            <Typography variant="body2" className={classes.availableToPayout}>
              <FormattedMessage
                id="ProfileReports.availableToPayout"
                defaultMessage="Available to payout"
              />
            </Typography>
          </div>
          <div className={classes.summaryRow}>
            <Typography variant="body2" className={classes.total}>
              <FormattedMessage
                id="ProfileReports.totalForAllTime"
                defaultMessage="Total for all time"
              />
            </Typography>
            <Typography variant="body2" className={classes.sum}>
              {payedSum}
              &nbsp;
              {currencySymbol}
            </Typography>
            <Typography variant="body2" className={classes.availableToPayout}>
              <FormattedMessage
                id="ProfileReports.paidOut"
                defaultMessage="Paid out"
              />
            </Typography>
          </div>
        </div>
        <div className={classes.payoutsPlate}>
          <div className={classes.payoutsPlateText}>
            <Typography variant="body2">
              <FormattedMessage
                id="ProfileReports.payoutsPlateText"
                defaultMessage="To withdraw money, click Request payout"
              />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage
                id="ProfileReports.payoutsPlateText2"
                defaultMessage="If you have any questions about payments, write to"
              />{' '}
              <a
                target="_blank"
                href={`mailto:${financeEmail}`}
                rel="noreferrer"
              >
                {financeEmail}
              </a>
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={!isSummaryAvailable}
            onClick={requestPayout}
          >
            <FormattedMessage
              id="ProfileReports.write"
              defaultMessage="Request payout"
            />
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReportPayouts;
